import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import BaseService from "@/services/base.service";
import { debtorRoutes, debtorWvpRoutes } from "@/router/debtor.js";
import { clerkDebtorRoutes, clerkCorporateRoutes } from "@/router/clerk.js";
import { adminRoutes } from "@/router/admin.js";
// import { corporateRoutes } from "@/router/corporate.js";
import { corporateRoutes } from "@/router/corporate_new.js";

const routes = [
  adminRoutes,
  clerkDebtorRoutes,
  clerkCorporateRoutes,
  debtorWvpRoutes,
  debtorRoutes,
  // corporateRoutes,
  corporateRoutes,
  {
    path: "/thank-you",
    name: "thank-you",
    component: () => import("../views/ThankYou.vue"),
    meta: {
      requiresAuth: true,
      role: "debtor,corp_debtor",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

const isItemAfterCurrentState = (currentState, route) => {
  const currentStateIndex = router
    .getRoutes()
    .findIndex((i) => i.name === currentState);
  const routeIndex = router.getRoutes().findIndex((i) => i.name === route.name);
  return routeIndex >= currentStateIndex;
};

const corporateInterceptor = (to, from ,next) => {
  if (store.getters.corporateCaseData) {
    if (isItemAfterCurrentState(store.getters.corporateCaseCurrentState, to)) {
      const currentState = to.name;
      BaseService.put(
        `/corp/cases/${store.getters.userData.corpCaseId}`,
        { currentState: currentState }
      )
        .then(() => store.commit("setCorporateCurrentState", currentState))
        .catch((err) => console.log(err));
    }
  }
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta?.requiresAuth)) {
    if (store.getters.caseData?.dataSent) {
      store.commit("setFormValid");
      store.commit("setInteractionStateValid");
    } else {
      if (to.meta.requiresInternalValidation) {
        store.commit("setFormInvalid");
      } else {
        store.commit("setFormValid");
      }
      if (to.meta.requiresInteraction) {
        store.commit("setInteractionStateInvalid");
      } else {
        store.commit("setInteractionStateValid");
      }
    }

    if (
      store.getters.userData?.role === "debtor" &&
      store.getters.userData &&
      store.getters.frontendState &&
      store.getters.frontendState.currentState
    ) {
      if (
        from.meta?.previous !== to.name &&
        isItemAfterCurrentState(store.getters.frontendState.currentState, to)
      ) {
        const data = { currentState: to.name };
        if (
          store.getters.userData.wvpYear > 0 &&
          store.getters.userData.wvpCaseId > 0
        ) {
          BaseService.put(
            `/cases/${store.getters.wvpCaseData.caseId}/wvp-frontend-state/${store.getters.wvpCaseData.id}`,
            data
          )
            .then(() => store.commit("setCurrentState", data.currentState))
            .catch((err) => console.log(err));
        } else {
          BaseService.updateFrontendState(
            store.getters.userData.caseId,
            data
          ).then((res) => store.commit("setCurrentState", data.currentState));
        }
      }
    }

    if (store.getters.userData?.role === 'corp_debtor') {
      corporateInterceptor(to, from, next);
      store.dispatch('retrieveCorporateCaseData');
    }

    const token = sessionStorage.getItem("token");
    if (!token) {
      next({
        path: "/login",
      });
    } else {
      if (!store.getters.role) {
        await store.dispatch("retrieveUserData");
        if (store.getters.role === "debtor") {
          if (
            store.getters.userData.wvpCaseId &&
            store.getters.userData.wvpYear
          ) {
            if (!store.getters.wvpCaseData) {
              store.dispatch("retrieveWvpCaseData");
              store.dispatch("retrieveWvpFrontendState");
            }
          } else {
            if (!store.getters.caseData) {
              store.dispatch("retrieveCaseData");
              store.dispatch("retrieveFrontendState");
            }
          }
        } else if (store.getters.role === 'corp_debtor') {
          if (store.getters.userData.corpCaseId) {
            // store.dispatch('retrieveCorporateCaseData');
          }
        }
      }

      if (!to.meta.role.includes(store.getters.role)) {
        next({
          path: "/login",
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
