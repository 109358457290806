const corporateRoutes = {
  path: "/corporate",
  component: () => import("@/layouts/AuthorizedCorporateLayout.vue"),
  children: [
    {
      path: "start",
      name: "corporate-entry",
      component: () => import("@/views/corporate/Start.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        next: "corp-general-data",
      },
    },
    {
      path: "general-data",
      name: "corp-general-data",
      component: () => import("@/views/corporate/GeneralData.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "start",
        next: "accounting",
      },
    },
    {
      path: "accounting",
      name: "accounting",
      component: () => import("@/views/corporate/Accounting.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "general-data",
        next: "employees",
      },
    },
    {
      path: "employees",
      name: "employees",
      component: () => import("@/views/corporate/Employees.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "accounting",
        next: "social-insurance",
      },
    },
    {
      path: "social-insurance",
      name: "social-insurance",
      component: () => import("@/views/corporate/SocialInsurance.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "employees",
        next: "fixed-assets",
      },
    },
    {
      path: "fixed-assets",
      name: "fixed-assets",
      component: () => import("@/views/corporate/FixedAssets.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "social-insurance",
        next: 'corp-overview',
      },
    },
    {
      path: "overview",
      name: "corp-overview",
      component: () => import("@/views/corporate/OverviewV2.vue"),
      meta: {
        requiresAuth: true,
        requiresInternalValidation: false,
        previous: "fixed-assets",
        next: null,
      },
    }
  ],
  meta: {
    requiresAuth: true,
    role: "corp_debtor",
  },
};

export { corporateRoutes };
