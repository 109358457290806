const adminRoutes = {
  path: "/admin",
  component: () => import("@/layouts/AuthorizedAdminLayout.vue"),
  children: [
    {
      path: "management/:type",
      name: "admin-management",
      component: () => import("../views/admin/Management.vue")
    },
    {
      path: "add/:type",
      name: "admin-add",
      component: () => import("../views/admin/AddEntity.vue"),
    },
    {
      path: "edit/:type/:id",
      name: "admin-edit",
      component: () => import("../views/admin/EditEntity.vue"),
    },
  ],
  meta: {
    requiresAuth: true,
    role: "admin",
  },
};

export { adminRoutes };
