import api from "@/services/api.service";

export default {
  find(url, id) {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  retrieve(url) {
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  retrieveFile(url) {
    return new Promise((resolve, reject) => {
      api
        .get(url, { responseType: "blob" })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  delete(url, id) {
    return new Promise((resolve, reject) => {
      api
        .delete(`${url}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  create(url, entity) {
    return new Promise((resolve, reject) => {
      api
        .post(`${url}`, entity)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  post(url, payload, headers) {
    return new Promise((resolve, reject) => {
      api
        .post(`${url}`, payload, headers)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  get(url) {
    return new Promise((resolve, reject) => {
      api
        .get(`${url}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  put(url, entity) {
    return new Promise((resolve, reject) => {
      api
        .put(`${url}`, entity)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  update(url, entity) {
    return new Promise((resolve, reject) => {
      api
        .put(`${url}/${entity.id}`, entity)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  partialUpdate(url, entity) {
    return new Promise((resolve, reject) => {
      api
        .patch(`${url}/${entity.id}`, entity)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateFrontendState(id, data) {
    return new Promise((resolve, reject) => {
      api
        .put(`/cases/${id}/frontend-state`, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
