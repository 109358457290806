import axios from "axios";

import {
  bearerInterceptor,
  unAuthenticated,
  authenticated,
} from "@/shared/config/axios-interceptor";

const client = axios.create();

client.defaults.baseURL = process.env.VUE_APP_API_URL;
client.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
// client.defaults.headers.post["Content-Type"] = "multipart/form-data";

client.interceptors.request.use(bearerInterceptor);
client.interceptors.response.use(authenticated, unAuthenticated);

export default client;
